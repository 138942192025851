import { sagas as authentication } from 'ducks/authentication';
import { sagas as bootstrap } from 'ducks/bootstrap';
import { sagas as buildings } from 'ducks/buildings';
import { sagas as calibrations } from 'ducks/calibrations';
import { sagas as deploys } from 'ducks/deploys';
import { sagas as deviceLogs } from 'ducks/deviceLogs';
import { sagas as devices } from 'ducks/devices';
import { sagas as equipment } from 'ducks/equipment';
import { sagas as equipmentAttachments } from 'ducks/equipmentAttachments';
import { sagas as equipmentCategories } from 'ducks/equipmentCategories';
import { sagas as indicators } from 'ducks/indicators';
import { sagas as installationPictures } from 'ducks/installationPictures';
import { sagas as installationZones } from 'ducks/installationZones';
import { sagas as loadBuilding } from 'ducks/loadBuilding';
import { sagas as logs } from 'ducks/logs';
import { sagas as measurements } from 'ducks/measurements';
import { sagas as metadata } from 'ducks/metadata';
import { sagas as metadataRules } from 'ducks/metadataRules';
import { sagas as protocols } from 'ducks/protocols';
import { sagas as readings } from 'ducks/readings';
import { sagas as templates } from 'ducks/templates';
import { sagas as users } from 'ducks/users';
import { sagas as wise_boxes } from 'ducks/wise_boxes';
import { all, fork } from 'redux-saga/effects';

export default function* rootSaga() {
    try {
        yield all([
            ...authentication,
            ...bootstrap,
            ...buildings,
            ...calibrations,
            ...deploys,
            ...deviceLogs,
            ...devices,
            ...equipment,
            ...equipmentAttachments,
            ...equipmentCategories,
            ...indicators,
            ...installationPictures,
            ...installationZones,
            ...loadBuilding,
            ...logs,
            ...measurements,
            ...metadata,
            ...metadataRules,
            ...protocols,
            ...readings,
            ...templates,
            ...users,
            ...wise_boxes
        ].map(saga => fork(saga)));
    } catch (error) {
        console.error(error);
    }
}
