import Button from 'Components/Buttons/Button';
import FormViewer from 'Components/FormViewer';
import { actions as deviceActions } from 'ducks/devices';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import usePrevious from 'utils/usePrevious';

const Component = props => {
    const { device, submitting } = props;
    const { onSave, uploadConfigFile } = props;
    const [file, setFile] = useState(null);
    const previousSubmitting = usePrevious(submitting);

    useEffect(() => {
        if (!submitting && previousSubmitting) {
            onSave();
        }
    }, [submitting]);

    const onChange = event => {
        let selectedFile = event.target.files[0];
        if (selectedFile && selectedFile.name === 'conf.sys') {
            setFile(selectedFile);
        } else {
            event.target.value = '';
        }
    };

    const onClick = () => uploadConfigFile(device.id, file);

    return (
        <FormViewer label="Upload File">
            <div style={{ marginLeft: 10, fontSize: 12 }}>* You must choose a valid file named conf.sys</div>
            <input
                accept=".sys"
                id="upload-file"
                onChange={onChange}
                style={{ margin: 10 }}
                type="file"
            />
            <label htmlFor="upload-file">
                <Button disabled={submitting || !file} label="Upload" onClick={onClick} submitting={submitting}/>
            </label>
        </FormViewer>
    );
};

const mapStateToProps = (state, ownProps) => ({
    device: state.devices.models[ownProps.deviceId],
    submitting: state.devices.submitting
});

const mapDispatchToProps = ({
    uploadConfigFile: deviceActions.uploadConfigFile
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
