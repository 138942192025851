// credits to https://stackoverflow.com/q/10420352/1787187
const getReadableFileSize = fileSizeInBytes => {
    let i = 0;
    const byteUnits = ['B', 'kiB', 'MiB'];

    while (fileSizeInBytes >= 512 && i < byteUnits.length - 1) {
        fileSizeInBytes = fileSizeInBytes / 1024;
        i++;
    }

    if (fileSizeInBytes !== Math.round(fileSizeInBytes)) {
        fileSizeInBytes = fileSizeInBytes.toFixed(2);
    }

    return `${fileSizeInBytes} ${byteUnits[i]}`;
};

export default getReadableFileSize;
