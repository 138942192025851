import ExpandedPanel from 'Components/ExpandedPanel';
import SimpleList from 'Components/SimpleList';
import Table from 'Components/SimpleTable';
import React from 'react';
import { Link } from 'react-router-dom';
import { unixToDate } from 'utils/date';
import getUserName from 'utils/getUserName';

const indicatorProperties = model => [
    { label: 'ID', content: model.id },
    { label: 'Name', content: model.name },
    { label: 'Description', content: model.description || '-' },
    { label: 'Ignore Status?', content: model.ignore_status ? 'Yes' : 'No' },
    //{ label: 'Status', content: <span style={{ color: statusToColor(model.status) }}>{model.status}</span> },
    { label: 'Current Device', content: model.device_id ? <Link to={`/devices/${model.device_id}`}>{model.device_name}</Link> : '-' },
    { label: 'Building', content: model.building_name },
    { label: 'Kind', content: model.kind_name },
    { label: 'Min Value', content: model.min_value },
    { label: 'Max Value', content: model.max_value },
    { label: 'Creation', content: `${getUserName(model.created_by)} @ ${unixToDate(model.created_at)}` },
    { label: 'Update', content: `${getUserName(model.updated_by)} @ ${unixToDate(model.updated_at)}` }
];

const historyProperties = [
    { label: 'ID', dataKey: 'id' },
    { label: 'Ratio', dataKey: 'ratio' },
    { label: 'Min Value', dataKey: 'min_value' },
    { label: 'Max Value', dataKey: 'max_value' },
    { label: 'Started at', dataKey: 'started_at' },
    { label: 'Ended at', dataKey: 'ended_at' }
];

const generateHistoryRows = model => model.metals.map(metal => ({
    ended_at: metal.to ? unixToDate(metal.to) : '-',
    id: metal.id,
    min_value: metal.min_value,
    max_value: metal.max_value,
    ratio: metal.ratio,
    started_at: unixToDate(metal.from)
}));

const Info = props => {
    const { model } = props;

    const indicatorAttributes = indicatorProperties(model);
    const historyRows = generateHistoryRows(model);

    return (
        <React.Fragment>
            <ExpandedPanel id="properties" header="Indicator details">
                <SimpleList content={indicatorAttributes}/>
            </ExpandedPanel>
            <ExpandedPanel id="measurements" header="Measurements">
                <Table columns={historyProperties} rows={historyRows}/>
            </ExpandedPanel>
        </React.Fragment>
    );
};

export default Info;
