import Form from 'Components/Forms/FormGenerator';
import FormViewer from 'Components/FormViewer';
import { actions } from 'ducks/indicators';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { serializeForm } from 'utils/forms/utils';

const Component = props => {
    const { indicators, measurement, submitting } = props;
    const { linkMeasurement } = props;

    const formSchema = [
        {
            type: 'select',
            label: 'Indicator',
            name: 'indicator_id',
            formData: Object.values(indicators).filter(indicator =>
                !indicator.measurement_id &&
                indicator.building_id === measurement.building_id &&
                indicator.kind_id === measurement.indicator_kind_id
            )
        }
    ];

    const onSubmit = values => {
        const data = serializeForm(values, formSchema, measurement);
        linkMeasurement(data.indicator_id, measurement.id);
    };

    return (
        <FormViewer label="Add To Indicator">
            <Form
                formSchema={formSchema}
                formInitialValues={measurement}
                isSubmitting={submitting}
                onSubmit={onSubmit}
            />
        </FormViewer>
    );
};

const mapStateToProps = state => {
    return {
        indicators: state.indicators.models,
        submitting: state.indicators.submitting
    };
};

const mapDispatchToProps = ({
    linkMeasurement: actions.linkMeasurement
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);

Component.propTypes = {
    measurement: PropTypes.object.isRequired
};
