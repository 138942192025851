import { sortBy } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import statusToColor from 'utils/statusToColor';

const Component = props => {
    const { uptime } = props;

    if (uptime) {
        const entries = sortBy(Object.entries(uptime), entry => -entry[1]);

        return (
            <>
                {entries.map((entry, index) => (
                    <span key={entry[0]} style={{ color: statusToColor(entry[0]) }}>{index !== 0 ? '|' : ' '} {entry[1]}% {entry[0].toUpperCase()} </span>)
                )}
            </>
        );
    } else {
        return (
            <span>-</span>
        );
    }
};

Component.propTypes = {
    uptime: PropTypes.object
};

export default Component;
