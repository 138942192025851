import EntityManageView from 'Components/EntityManageView';
import { actions } from 'ducks/equipmentAttachments';
import React from 'react';
import { connect } from 'react-redux';
import validators from 'utils/forms/validators';

const Component = props => {
    const { model } = props;

    const formSchema = [
        {
            type: 'text',
            label: 'Name\x2a',
            name: 'name',
            validate: [validators.required]
        },
        {
            type: 'textArea',
            label: 'Comment',
            name: 'comment'
        }
    ];

    return (
        <EntityManageView
            formSchema={formSchema}
            formInitialValues={model}
            label="Edit Attachment"
            {...props}
        />
    );
};

const mapStateToProps = state => ({
    model: state.equipmentAttachments.models[state.drawerView.entityId],
    submitting: state.equipmentAttachments.submitting
});

const mapDispatchToProps = ({
    create: actions.create,
    update: actions.update
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
