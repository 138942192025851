import { createSelector } from 'reselect';

const calibrations = state => state.calibrations.models;
const measurementId = (_, measurementId) => measurementId;

export default createSelector(
    calibrations,
    measurementId,
    (calibrations, measurementId) => Object.values(calibrations).filter(calibration => calibration.measurement_id === measurementId)
);
