import Drawer from 'Components/Drawer';
import Panel from 'Components/Panel';
import { actions as deviceActions } from 'ducks/devices';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import icons from 'utils/ui/icons';
import CleanMeasurementData from './CleanMeasurementData';
import Table from './Table';
import UploadFtt from './UploadFtt';

const Component = props => {
    const { device, submitting } = props;
    const { cleanMeasurementData, deleteFuture, uploadFft } = props;

    const [drawer, setOpenDrawer] = useState(null);
    const closeDrawer = () => setOpenDrawer(null);

    let buttons = [];

    if (device.new_wise_box) {
        buttons = [
            {
                icon: icons.debug,
                onClick: () => setOpenDrawer('deleteData'),
                tooltip: 'Delete measurement data'
            }
        ];
    } else if (device.wise_box) {
        buttons = [
            {
                icon: icons.bug,
                confirmationHeader: 'Delete Confirmation',
                confirmationText: 'Are you sure you want to delete future data?',
                submitting: submitting,
                onClick: () => deleteFuture(device.id),
                tooltip: 'Delete future data'
            },
            {
                icon: icons.cloudUpload,
                onClick: () => setOpenDrawer('uploadFtt'),
                tooltip: 'Upload FTT files'
            }
        ];
    }

    let DrawerContent;
    if (drawer === 'uploadFtt') {
        DrawerContent = (
            <UploadFtt device={device} onSave={closeDrawer} submitting={submitting} uploadFft={uploadFft}/>
        );
    } else if (drawer === 'deleteData') {
        DrawerContent = (
            <CleanMeasurementData cleanMeasurementData={cleanMeasurementData} device={device} onSave={closeDrawer} submitting={submitting}/>
        );
    }

    const panelFields = { buttons };

    let TableComponent = (
        <Table device={device}/>
    );

    return (
        <React.Fragment>
            <Panel {...panelFields}>
                {TableComponent}
            </Panel>
            <Drawer big={drawer === 'showIndicator'} onClose={closeDrawer} open={!!drawer}>
                {DrawerContent}
            </Drawer>
        </React.Fragment>
    );
};

const mapStateToProps = state => ({
    submitting: state.devices.submitting
});

const mapDispatchToProps = ({
    cleanMeasurementData: deviceActions.cleanMeasurementData,
    deleteFuture: deviceActions.deleteFuture,
    uploadFtt: deviceActions.uploadFtt
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
