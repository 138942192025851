import Form from 'Components/Forms/FormGenerator';
import FormViewer from 'Components/FormViewer';
import { actions } from 'ducks/installationZones';
import React from 'react';
import { connect } from 'react-redux';
import { serializeForm } from 'utils/forms/utils';
import validators from 'utils/forms/validators';

const Component = props => {
    const { buildingId, parentId, submitting } = props;
    const { onSave, create } = props;

    const formSchema = [
        {
            label: 'Name\x2a',
            name: 'name',
            type: 'text',
            validate: [validators.required]
        },
        {
            type: 'textArea',
            label: 'Description',
            name: 'description',
            rows: 8
        }
    ];

    const onSubmit = values => {
        const data = serializeForm(values, formSchema);
        if (parentId) {
            data.parent_id = parentId;
        } else {
            data.building_id = buildingId;
        }

        create(data, onSave);
    };

    return (
        <FormViewer label="Add Installation Zone">
            <Form
                formSchema={formSchema}
                isSubmitting={submitting}
                onSubmit={onSubmit}
            />
        </FormViewer>
    );
};

const mapStateToProps = state => ({
    submitting: state.installationZones.submitting
});

const mapDispatchToProps = ({
    create: actions.create
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
