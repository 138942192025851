import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import IconButton from 'Components/Buttons/IconButton';
import ExpandedPanel from 'Components/ExpandedPanel';
import Panel from 'Components/Panel';
import Loading from 'Components/ScopedLoader';
import SimpleTable from 'Components/SimpleTable';
import TabsToolbar from 'Components/TabsToolbar';
import { actions } from 'ducks/calibrations';
import styles from 'Pages/Equipment/Show/styles';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import calibrationsSelector from 'selectors/calibrations';
import getReadableFileSize from 'utils/getReadableFileSize';
import icons from 'utils/ui/icons';

const transformPicture = calibration => {
    if (calibration.picture_url) {
        return (
            <>
                {calibration.picture_mime_type} | {getReadableFileSize(calibration.picture_size)} | <a href={calibration.picture_url} rel="noreferrer" target="_blank">view</a>
            </>
        );
    }

    return '-';
};

const columns = [
    { label: 'ID', dataKey: 'id' },
    { label: 'Date', dataKey: 'date' },
    { label: 'Value', dataKey: 'value' },
    { label: 'Picture', dataKey: 'picture', transform: transformPicture },
    { label: 'Actions', dataKey: 'actions' }
];

const CalibrationsPage = props => {
    const { calibrations, classes, loading, measurement, submitting } = props;
    const { createCalibration, fetchAll, remove } = props;

    useEffect(() => {
        fetchAll({ measurement_id: measurement.id });
    }, [measurement.id]);

    if (loading) {
        return (
            <Panel>
                <Loading/>
            </Panel>
        );
    }

    const
        buttons = [
            {
                icon: icons.add,
                onClick:
                createCalibration,
                tooltip: 'Add Calibration'
            }
        ],
        tabs = [{ text: 'Calibrations', id: 'calibrations' }];

    const rows = calibrations.map(calibration => {
        const row = { ...calibration };

        row.actions = (
            <IconButton
                confirmationText="Are you sure you want to remove this calibration?"
                icon={icons.destroy}
                onClick={() => remove(calibration.id)}
                submitting={submitting}
                tooltip="Remove calibration"
            />
        );

        return row;
    });

    return (
        <Grid item xs={12} className={classes.mainContainer}>
            <Grid container className={classes.headerContainer}>
                <TabsToolbar buttons={buttons} tabs={tabs}/>
            </Grid>
            <Grid item xs={12} className={classes.bottomContainer}>
                <ExpandedPanel id="calibrations">
                    <SimpleTable columns={columns} rows={rows}/>
                </ExpandedPanel>
            </Grid>
        </Grid>
    );

};

const mapStateToProps = (state, ownProps) => ({
    calibrations: calibrationsSelector(state, ownProps.measurement.id),
    loading: state.calibrations.loading,
    submitting: state.calibrations.submitting
});

const mapDispatchToProps = ({
    fetchAll: actions.fetchAll,
    remove: actions.remove
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CalibrationsPage));
