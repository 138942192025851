import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import ExpandedPanel from 'Components/ExpandedPanel';
import SimpleList from 'Components/SimpleList';
import TabsToolbar from 'Components/TabsToolbar';
import { actions } from 'ducks/installationPictures';
import React from 'react';
import { connect } from 'react-redux';
import { unixToDate } from 'utils/date';
import getReadableFileSize from 'utils/getReadableFileSize';
import getUserName from 'utils/getUserName';
import icons from 'utils/ui/icons';
import styles from './styles';

const Component = props => {
    const { classes, installationPicture, submitting } = props;
    const { setOpenDrawer, remove } = props;

    const attributes = [
        { label: 'ID', content: installationPicture.id },
        { label: 'Comment', content: installationPicture.comment },
        { label: 'URL', content: <a href={installationPicture.url} rel="noreferrer" target="_blank">Open in new window</a> },
        { label: 'File Size', content: getReadableFileSize(installationPicture.size) },
        { label: 'Type', content: installationPicture.mime_type },
        { label: 'Dimensions', content: `${installationPicture.width}x${installationPicture.height} px` },
        { label: 'Created', content: `${unixToDate(installationPicture.created_at)} by ${getUserName(installationPicture.created_by)}` },
        { label: 'Updated', content: `${unixToDate(installationPicture.updated_at)} by ${getUserName(installationPicture.updated_by)}` }
    ];

    const onEditClick = () => setOpenDrawer({ name: 'EditPicture', props: { installationPicture } });
    const onDelete = () => remove(installationPicture.id, () => setOpenDrawer({}));

    const buttons = [
        { icon: icons.edit, onClick: onEditClick, tooltip: 'Edit Picture' },
        {
            icon: icons.trash,
            confirmationHeader: 'Delete Confirmation',
            confirmationText: 'Are you sure you want to delete this picture?',
            submitting,
            onClick: onDelete,
            tooltip: 'Delete'
        }
    ];

    const tabs = [
        { text: 'Details', id: 'details' },
        { text: 'Picture', id: 'picture' }
    ];

    let Comment;
    if (installationPicture.comment) {
        Comment = (
            <div className={classes.comment}>{installationPicture.comment}</div>
        );
    }
    return (
        <Grid item xs={12} className={classes.mainContainer}>
            <Grid container className={classes.headerContainer}>
                <TabsToolbar buttons={buttons} tabs={tabs}/>
            </Grid>
            <Grid item xs={12} className={classes.bottomContainer}>
                <ExpandedPanel id="picture" header="Picture">
                    <img alt={installationPicture.comment} src={installationPicture.url} style={{ maxWidth: '100%' }}/>
                    {Comment}
                </ExpandedPanel>
                <ExpandedPanel id="details" header="Details">
                    <SimpleList content={attributes}/>
                </ExpandedPanel>
            </Grid>
        </Grid>
    );
};

const mapStateToProps = state => ({
    submitting: state.installationPictures.submitting
});

const mapDispatchToProps = ({
    remove: actions.remove
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Component));
