import Form from 'Components/Forms/FormGenerator';
import FormViewer from 'Components/FormViewer';
import React from 'react';
import { serializeForm } from 'utils/forms/utils';
import validators from 'utils/forms/validators';

const Component = props => {
    const { device, submitting } = props;
    const { cleanMeasurementData, onSave } = props;

    const formSchema = [
        {
            type: 'number',
            label: 'From\x2a',
            name: 'from',
            validate: [validators.required]
        },
        {
            type: 'number',
            label: 'To\x2a',
            name: 'to',
            validate: [validators.required]
        }
    ];

    const onSubmit = values => {
        const data = serializeForm(values, formSchema);
        cleanMeasurementData(device.id, data.from, data.to, onSave);
    };

    return (
        <FormViewer label="Clean Measurement Data">
            <Form
                formSchema={formSchema}
                isSubmitting={submitting}
                onSubmit={onSubmit}
            />
        </FormViewer>
    );
};

export default Component;
