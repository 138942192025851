import { createSelector } from 'reselect';

const installationPictures = state => state.installationPictures.models;
const deviceId = (_, deviceId) => deviceId;

export default createSelector(
    installationPictures,
    deviceId,
    (installationPictures, deviceId) => Object.values(installationPictures).filter(picture => picture.device_id === deviceId)
);
