import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import ExpandedPanel from 'Components/ExpandedPanel';
import { actions } from 'ducks/readings';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Details from './Details';
import Measurements from './Measurements';
import styles from './styles';
import Toolbar from './Toolbar';

const Component = props => {
    const { classes, disableButtons = false, reading } = props;
    const { fetchOne } = props;

    useEffect(() => {
        fetchOne(reading.id);
    }, [reading.id]);

    return (
        <Grid item xs={12} className={classes.mainContainer}>
            <Grid container className={classes.headerContainer}>
                <Toolbar disableButtons={disableButtons} reading={reading}/>
            </Grid>
            <Grid item xs={12} className={classes.bottomContainer}>
                <ExpandedPanel id="properties" header="Details">
                    <Details reading={reading}/>
                </ExpandedPanel>
                <ExpandedPanel id="measurements" header="Measurements">
                    <Measurements measurements={reading.measurements}/>
                </ExpandedPanel>
            </Grid>
        </Grid>
    );
};

const mapStateToProps = (state, ownProps) => ({
    reading: state.readings.models[ownProps.id || state.drawerView.entityId]
});

const mapDispatchToProps = ({
    fetchOne: actions.fetchOne,
    remove: actions.remove
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Component));
