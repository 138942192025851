import SimpleList from 'Components/SimpleList';
import BuildingsList from 'Components/SimpleList/Buildings';
import { uniqBy } from 'lodash';
import React from 'react';
import { unixToDate } from 'utils/date';
import displayMetadata from 'utils/displayMetadata';
import getUserName from 'utils/getUserName';

const generateReadingProperties = reading => {
    const
        buildings = uniqBy(reading.measurements?.map(({ building_id, building_name }) => ({ id: building_id, name: building_name })), 'id') || [],
        organizations = uniqBy(reading.measurements?.map(({ organization_id, organization_name }) => ({ id: organization_id, name: organization_name })), 'id') || [];

    return [
        { label: 'ID', content: reading.id },
        { label: 'Name', content: reading.name },
        { label: 'Activate Measurement?', content: reading.activate_measurement ? 'Yes' : 'No' },
        { label: 'Measurement Count', content: reading.measurements_count },
        { label: `Buildings (${buildings.length})`, content: <BuildingsList rows={buildings}/> },
        { label: `Organizations (${organizations.length})`, content: organizations.map(({ name }) => name).sort().join(', ') },
        { label: 'Creation', content: `${getUserName(reading.created_by)} @ ${unixToDate(reading.created_at)}` },
        { label: 'Update', content: `${getUserName(reading.updated_by)} @ ${unixToDate(reading.updated_at)}` }
    ];
};

const Details = props => {
    const { reading } = props;

    const content = [
        ...generateReadingProperties(reading),
        ...displayMetadata(reading.metadata_values)
    ];

    return (
        <SimpleList content={content}/>
    );
};

export default Details;
