import PrivateRoute from 'Components/PrivateRoute';
import BuildingsPage from 'Pages/BuildingsPage';
import EquipmentTable from 'Pages/Equipment/Table';
import EquipmentCategoriesTable from 'Pages/EquipmentCategories/Table';
import ShowIndicator from 'Pages/Indicators/Show';
import MapPage from 'Pages/Map';
import MetadataTable from 'Pages/Metadata/Table';
import MetadataRulesTable from 'Pages/MetadataRules/Table';
import Profile from 'Pages/Profile';
import TemplatesTable from 'Pages/Templates/Table';
import TreePage from 'Pages/Tree';
import UsersTable from 'Pages/Users/Table';
import WiseBoxesTable from 'Pages/WiseBoxes/Table';
import React from 'react';
import { Redirect, Switch } from 'react-router-dom';

const Routes = () => (
    <Switch>
        <PrivateRoute exact path="/map" component={MapPage}/>
        <PrivateRoute exact path="/buildings" component={BuildingsPage}/>

        <PrivateRoute exact path="/profile" component={Profile}/>
        <PrivateRoute exact path="/indicators/:indicatorId" component={ShowIndicator}/>

        <PrivateRoute exact path="/buildings/:buildingId/:section?" component={TreePage}/>
        <PrivateRoute exact path="/devices/:deviceId/:section?" component={TreePage}/>

        <PrivateRoute exact path="/equipment" component={EquipmentTable}/>
        <PrivateRoute exact path="/equipment_categories" component={EquipmentCategoriesTable}/>
        <PrivateRoute exact path="/metadatas" component={MetadataTable}/>
        <PrivateRoute exact path="/metadata_rules" component={MetadataRulesTable}/>

        <PrivateRoute exact path="/templates" component={TemplatesTable}/>
        <PrivateRoute exact path="/unclaimed" component={WiseBoxesTable}/>

        <PrivateRoute exact path="/users" component={UsersTable}/>

        <Redirect exact from="/" to="/map"/>
        <Redirect to="/map"/>
    </Switch>
);

export default Routes;
