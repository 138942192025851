import TabsToolbar from 'Components/TabsToolbar';
import { actions as drawerViewActions } from 'ducks/drawerView';
import { actions as readingActions } from 'ducks/readings';
import React from 'react';
import { connect } from 'react-redux';
import icons from 'utils/ui/icons';

const Toolbar = props => {
    const { disableButtons = false, loading, reading } = props;
    const { editEntity, previousState, remove } = props;

    const
        buttons = [
            { icon: icons.back, onClick: () => previousState(), tooltip: 'Back' },
            { icon: icons.editConfig, onClick: () => editEntity(reading.id, 'READING'), tooltip: 'Edit' },
            {
                icon: icons.trash,
                confirmationHeader: 'Delete Confirmation',
                confirmationText: 'Are you sure you want to delete this reading?',
                submitting: loading,
                onClick: () => remove(reading.id, () => props.onClose()),
                tooltip: 'Delete'
            }
        ],
        tabs = [
            { text: 'reading details', id: 'properties' },
            { text: 'Measurements', id: 'measurements' }
        ];

    return (
        <TabsToolbar buttons={disableButtons ? [] : buttons} tabs={tabs}/>
    );
};

const mapStateToProps = state => ({
    loading: state.readings.loading
});

const mapDispatchToProps = ({
    previousState: drawerViewActions.previousState,
    editEntity: drawerViewActions.editEntity,
    remove: readingActions.remove
});

export default connect(mapStateToProps, mapDispatchToProps)(Toolbar);
