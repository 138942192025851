import Circle from 'Components/Circle';
import Drawer from 'Components/Drawer';
import Panel from 'Components/Panel';
import SimpleList from 'Components/SimpleList';
import React, { useState } from 'react';
import { unixToDate } from 'utils/date';
import getUserName from 'utils/getUserName';
import icons from 'utils/ui/icons';
import globals from '~/globals';
import CreateTemplate from './CreateTemplate';
import ReclaimWiseBox from './ReclaimWiseBox';

const generateLastModification = device => {
    const color = device.locally_synced ? '#2C6700' : '#B94A48';

    return (
        <>
            <Circle color={color}/>
            &nbsp;
            {unixToDate(device.configuration_last_modification)}
        </>
    );
};

const generateRegistered = device => {
    const color = device.registered_at ? '#2C6700' : '#B94A48';

    return (
        <>
            <Circle color={color}/>
            &nbsp;
            {device.registered_at ? unixToDate(device.registered_at) : 'Not yet registered'}
        </>
    );
};

const Component = props => {
    const { device } = props;

    const [drawer, setOpenDrawer] = useState(null);
    const closeDrawer = () => setOpenDrawer(null);

    const wiseBoxAttributes = [
        { label: 'Balena ID', content: device.balena_id ? device.balena_id : 'Not settled' },
        { label: 'Balena UUID', content: device.balena_uuid ? device.balena_uuid : 'Not settled' },
        {
            label: 'Claimed',
            content: device.claimed_at ? `${unixToDate(device.claimed_at)} by ${getUserName(device.claimed_by)}` : 'not claimed'
        },
        { label: 'Registration Date', content: generateRegistered(device) },
        { label: 'Last Configuration Change', content: generateLastModification(device) }
    ];

    const wiseBoxButtons = [];

    wiseBoxButtons.push(
        {
            icon: icons.reclaim,
            onClick: () => setOpenDrawer('reclaim'),
            tooltip: 'Reclaim WiseBox'
        },
        {
            icon: icons.templateCreate,
            onClick: () => setOpenDrawer('createTemplate'),
            tooltip: 'Create New Template'
        },
        {
            icon: icons.templateDownload,
            onClick: () => window.open(`${globals.apiUrl}/devices/${device.id}/template`, '_blank'),
            tooltip: 'Download Template'
        }
    );

    let DrawerContent;
    if (drawer === 'createTemplate') {
        DrawerContent = (
            <CreateTemplate id={device.id} closeDrawer={closeDrawer}/>
        );
    } else if (drawer === 'reclaim') {
        DrawerContent = (
            <ReclaimWiseBox id={device.id} closeDrawer={closeDrawer}/>
        );
    }

    return (
        <>
            <Panel buttons={wiseBoxButtons}>
                <SimpleList content={wiseBoxAttributes}/>
            </Panel>
            <Drawer onClose={closeDrawer} open={!!drawer}>
                {DrawerContent}
            </Drawer>
        </>
    );
};

export default Component;
