import EntityView from 'Components/EntityView';
import { actions } from 'ducks/equipmentAttachments';
import React from 'react';
import { connect } from 'react-redux';
import { unixToDate } from 'utils/date';
import getReadableFileSize from 'utils/getReadableFileSize';
import getUserName from 'utils/getUserName';

const getProperties = model => [
    { label: 'ID', content: model.id },
    { label: 'Name', content: model.name },
    { label: 'Comment', content: model.comment || '-' },
    { label: 'Type', content: model.mime_type },
    { label: 'Size', content: getReadableFileSize(model.size) },
    { label: 'File', content: <a href={model.url} rel="noreferrer" target="_blank">download</a> },
    { label: 'Creation', content: `${getUserName(model.created_by)} @ ${unixToDate(model.created_at)}` },
    { label: 'Update', content: `${getUserName(model.updated_by)} @ ${unixToDate(model.updated_at)}` }
];

const Component = props => {
    return (
        <EntityView
            entityType="ATTACHMENT"
            tabsHeader="Attachment Properties"
            properties={getProperties}
            {...props}
        />
    );
};

const mapStateToProps = (state, ownProps) => {
    const
        id = ownProps.id || state.drawerView.entityId,
        model = state.equipmentAttachments.models[id];

    return { id, model };
};

const mapDispatchToProps = ({
    remove: actions.remove
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
