import { call, delay, put, race, take, takeLatest } from 'redux-saga/effects';
import columns from 'utils/columns/buildings';
import { initialStateFactory, reducerFactory, sagasFactory } from 'utils/factories';
import actionsFactory from 'utils/factories/actions';
import { apiRequest, uploadFiles } from 'utils/request';
import { handleError } from 'utils/sagasHelpers';
import { BUILDING, FETCH_BUILDINGS, FULFILLED, LOAD_BUILDING, REFRESH_BUILDING_STATUS, REJECTED, UPLOAD_DEVICES } from './actionTypes';

const START_POOLING_BUILDINGS = 'START_POOLING_BUILDINGS';
const STOP_POOLING_BUILDINGS = 'STOP_POOLING_BUILDINGS';

// actions
const actions = actionsFactory(BUILDING);

actions.refreshStatus = id => ({
    payload: id,
    type: REFRESH_BUILDING_STATUS
});

actions.startPoolingBuildings = () => ({
    type: START_POOLING_BUILDINGS
});

actions.stopPoolingBuildings = () => ({
    type: STOP_POOLING_BUILDINGS
});

actions.uploadDevices = (id, file) => ({
    payload: { id, file },
    type: UPLOAD_DEVICES
});

export { actions };

// reducer
const initialState = { ...initialStateFactory(columns), uploadingDevices: false };

const reducer = reducerFactory(BUILDING);

export default (state = initialState, action) => {
    const { type } = action;

    switch (type) {
        case REFRESH_BUILDING_STATUS:
            return reducer({ ...state, submitting: true }, action);

        case REFRESH_BUILDING_STATUS + FULFILLED:
        case REFRESH_BUILDING_STATUS + REJECTED:
            return reducer({ ...state, submitting: false }, action);

        case UPLOAD_DEVICES:
            return reducer({ ...state, uploadingDevices: true }, action);

        case UPLOAD_DEVICES + FULFILLED:
        case UPLOAD_DEVICES + REJECTED:
            return reducer({ ...state, uploadingDevices: false }, action);

        default:
            return reducer(state, action);
    }
};

// sagas
const sagas = sagasFactory(BUILDING, '/buildings', { searchInputDelay: 500 });

const POOL_INTERVAL = 60 * 1000;

function* poolBuildings() {
    try {
        while (true) {
            yield put({ type: FETCH_BUILDINGS });
            yield delay(POOL_INTERVAL);
        }
    } catch (error) {
        console.error(error);
    }
}

function* watchBuildingsPooling() {
    try {
        while (true) {
            yield take(START_POOLING_BUILDINGS);
            yield race({
                task: call(poolBuildings),
                cancel: take(STOP_POOLING_BUILDINGS)
            });
        }
    } catch (error) {
        console.error(error);
    }
}

function* watchRefreshBuildingStatus() {
    yield takeLatest(REFRESH_BUILDING_STATUS, function* ({ payload: id }) {
        try {
            yield apiRequest(`/buildings/${id}/refresh_status`, 'PUT');
            yield put({ type: REFRESH_BUILDING_STATUS + FULFILLED });
            yield put({ type: LOAD_BUILDING, payload: id });
        } catch (error) {
            const errorObject = handleError(REFRESH_BUILDING_STATUS, error);
            yield put(errorObject);
        }
    });
}

function* watchUploadDevices() {
    yield takeLatest(UPLOAD_DEVICES, function* ({ payload: { id, file } }) {
        try {
            yield uploadFiles(`/buildings/${id}/upload_devices`, 'POST', file);
            yield put({ type: UPLOAD_DEVICES + FULFILLED });
            yield put({ type: LOAD_BUILDING, payload: id });
        } catch (error) {
            const errorObject = handleError(UPLOAD_DEVICES, error);
            yield put(errorObject);
        }
    });
}

sagas.push(
    watchBuildingsPooling,
    watchRefreshBuildingStatus,
    watchUploadDevices
);

export { sagas };
