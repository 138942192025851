import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Drawer from 'Components/Drawer';
import Panel from 'Components/Panel';
import { actions } from 'ducks/installationPictures';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import installationPicturesSelector from 'selectors/installationPictures';
import icons from 'utils/ui/icons';
import EditPicture from './Edit';
import NewPicture from './New';
import Picture from './Picture';
import ShowPicture from './Show';

const Component = props => {
    const { device, installationPictures } = props;
    const { fetchAll } = props;

    useEffect(() => {
        fetchAll({ device_id: device.id });
    }, [device.id]);

    const [drawer, setOpenDrawer] = useState({});
    const closeDrawer = () => setOpenDrawer({});

    let DrawerContent;
    if (drawer.name === 'NewPicture') {
        DrawerContent = <NewPicture deviceId={device.id} onSave={closeDrawer}/>;
    } else if (drawer.name === 'ShowPicture') {
        DrawerContent = <ShowPicture {...drawer.props} setOpenDrawer={setOpenDrawer}/>;
    } else if (drawer.name === 'EditPicture') {
        DrawerContent = <EditPicture {...drawer.props} closeDrawer={closeDrawer}/>;
    }

    const buttons = [
        {
            icon: icons.add,
            onClick: () => setOpenDrawer({ name: 'NewPicture' }),
            tooltip: 'Add Picture'
        }
    ];

    let Photos;
    if (installationPictures.length) {
        Photos = installationPictures.map(installationPicture => {
            const onClick = () => setOpenDrawer({ name: 'ShowPicture', props: { installationPicture } });

            return (
                <Grid item key={installationPicture.id} onClick={onClick} xs={3}>
                    <Picture alt={installationPicture.comment} src={installationPicture.url}/>
                </Grid>
            );
        });
    } else {
        Photos = (
            <Grid item xs={12}>
                <Typography variant="subtitle2" style={{ marginTop: 10 }}>
                    No pictures to display.
                </Typography>
            </Grid>
        );
    }

    return (
        <>
            <Panel buttons={buttons}>
                <Grid container spacing={8}>
                    {Photos}
                </Grid>
            </Panel>
            <Drawer big={drawer.name === 'ShowPicture'} onClose={closeDrawer} open={!!drawer.name}>
                {DrawerContent}
            </Drawer>
        </>
    );
};

const mapStateToProps = (state, ownProps) => {
    const installationPictures = installationPicturesSelector(state, ownProps.device.id);

    return {
        installationPictures
    };
};

const mapDispatchToProps = ({
    fetchAll: actions.fetchAll
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
