import Form from 'Components/Forms/FormGenerator';
import FormViewer from 'Components/FormViewer';
import { actions as measurementActions } from 'ducks/measurements';
import React from 'react';
import { connect } from 'react-redux';
import selectorMetadatas from 'selectors/metadatas';
import { metadataFields, serializeForm } from 'utils/forms/utils';
import validators from 'utils/forms/validators';

const Component = props => {
    const { measurement, metadatas, submitting } = props;
    const { closeDrawer, update } = props;

    const formSchema = [
        {
            type: 'text',
            label: 'Name\x2a',
            name: 'name',
            validate: [validators.required]
        },
        ...metadataFields(metadatas, measurement)
    ];

    const onSubmit = values => {
        update(
            measurement.id,
            serializeForm(values, formSchema, measurement),
            closeDrawer
        );
    };

    return (
        <FormViewer label="Edit Details">
            <Form
                formSchema={formSchema}
                formInitialValues={measurement}
                isSubmitting={submitting}
                onSubmit={onSubmit}
            />
        </FormViewer>
    );
};

const mapStateToProps = (state, ownProps) => ({
    measurement: state.measurements.models[ownProps.id],
    metadatas: selectorMetadatas.metadataFromMeasurement(state, ownProps),
    submitting: state.measurements.submitting
});

const mapDispatchToProps = ({
    update: measurementActions.update
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
