import withStyles from '@material-ui/core/styles/withStyles';
import React from 'react';
import styles from './styles';

const Component = props => {
    const { alt, classes, src } = props;

    return (
        <img alt={alt} className={classes.picture} src={src}/>
    );
};

export default withStyles(styles)(Component);
