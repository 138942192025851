import Drawer from 'Components/Drawer';
import Panel from 'Components/Panel';
import Table from 'Components/SimpleTable';
import { sortBy } from 'lodash';
import NewDevice from 'Pages/Tree/Device/New';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { selectRootDevices } from 'selectors/devices';
import findWhere from 'selectors/findWhere';
import { unixToDate } from 'utils/date';
import getUserName from 'utils/getUserName';
import icons from 'utils/ui/icons';
import UploadDevices from './UploadDevices';

const rootDeviceProperties = [
    { label: 'Name', dataKey: 'name' },
    { label: 'Last Update', dataKey: 'updated_at', transform: model => `${getUserName(model.updated_by)} @ ${unixToDate(model.updated_at)}` }
];

const Component = props => {
    const { buildingId, rootDevices } = props;
    const installationZones = findWhere(Object.values(props.installationZones), { building_id: buildingId });

    const [drawer, setOpenDrawer] = useState(null);
    const closeDrawer = () => setOpenDrawer(null);

    const buttons = [
        { icon: icons.addEntity, onClick: () => setOpenDrawer('newDevice'), tooltip: 'New child device' },
        { icon: icons.upload, onClick: () => setOpenDrawer('uploadDevices'), tooltip: 'Upload devices file' }
    ];

    let DrawerContent;
    if (drawer === 'newDevice') {
        DrawerContent = (
            <NewDevice buildingId={buildingId} installationZones={installationZones} parentDeviceId={null} onSave={closeDrawer}/>
        );
    } else if (drawer === 'uploadDevices') {
        DrawerContent = (
            <UploadDevices buildingId={buildingId}/>
        );
    }

    return (
        <React.Fragment>
            <Panel buttons={buttons}>
                <Table columns={rootDeviceProperties} rows={sortBy(rootDevices, 'name')}/>
            </Panel>
            <Drawer onClose={closeDrawer} open={!!drawer}>
                {DrawerContent}
            </Drawer>
        </React.Fragment>
    );
};

const mapStateToProps = (state, ownProps) => ({
    rootDevices: selectRootDevices(state, ownProps),
    installationZones: state.installationZones.models
});

export default connect(mapStateToProps)(Component);
