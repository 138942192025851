import { put, takeLatest } from 'redux-saga/effects';
import { initialStateFactory, reducerFactory, sagasFactory } from 'utils/factories';
import actionsFactory from 'utils/factories/actions';
import { apiRequest } from 'utils/request';
import { handleError } from 'utils/sagasHelpers';
import { FETCH_INDICATOR, FETCH_MEASUREMENT, FULFILLED, INDICATOR, LOAD_BUILDING, REJECTED, UNLINK_MEASUREMENT } from './actionTypes';

const LINK_MEASUREMENT = 'LINK_MEASUREMENT';

// actions
const actions = actionsFactory(INDICATOR);

actions.linkMeasurement = (id, measurementId) => ({
    payload: { id, measurementId },
    type: LINK_MEASUREMENT
});

actions.unlinkMeasurement = payload => ({
    payload,
    type: UNLINK_MEASUREMENT
});

export { actions };

// reducer
const initialState = initialStateFactory();
const reducer = reducerFactory(INDICATOR);

export default (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case LINK_MEASUREMENT:
        case UNLINK_MEASUREMENT:
            return reducer({ ...state, errorMessage: '', submitting: true }, action);

        case LINK_MEASUREMENT + FULFILLED:
        case UNLINK_MEASUREMENT + FULFILLED:
            return reducer({ ...state, errorMessage: '', submitting: false }, action);

        case LINK_MEASUREMENT + REJECTED:
        case UNLINK_MEASUREMENT + REJECTED:
            return reducer({ ...state, errorMessage: payload, submitting: false }, action);

        default:
            return reducer(state, action);
    }
};

// sagas
const sagas = sagasFactory(INDICATOR, '/indicators');

function* watchLinkMeasurement() {
    yield takeLatest(LINK_MEASUREMENT, function* ({ payload }) {
        const { id, measurementId } = payload;
        try {
            const response = yield apiRequest(`/indicators/${id}/link_measurement`, 'PUT', { measurement_id: measurementId });
            yield put({ type: LINK_MEASUREMENT + FULFILLED });
            yield put({ type: LOAD_BUILDING, payload: response.data.building_id });
        } catch (error) {
            const errorObject = handleError(LINK_MEASUREMENT, error);
            yield put(errorObject);
        }
    });
}

function* watchUnlinkMeasurement() {
    yield takeLatest(UNLINK_MEASUREMENT, function* ({ payload }) {
        try {
            const response = yield apiRequest(`/indicators/${payload.indicatorId}/unlink_measurement`, 'POST');
            yield put({ type: UNLINK_MEASUREMENT + FULFILLED, payload: response.data });
            yield put({ type: FETCH_INDICATOR + FULFILLED, payload: response.data });
            yield put({ type: FETCH_MEASUREMENT, payload: payload.measurementId });
        } catch (error) {
            const errorObject = handleError(UNLINK_MEASUREMENT, error);
            yield put(errorObject);
        }
    });
}

sagas.push(
    watchLinkMeasurement,
    watchUnlinkMeasurement
);

export { sagas };
