import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import startApp from 'utils/startApp';
import history from '~/history';
import theme from '~/theme';
import App from './App';
import store from './store';

// let's try to use only fas icons
library.add(fab);
library.add(fas);

startApp()
.then(data => {
    if (data.config) {
        if (window.location.pathname === '/login') {
            history.replace('/');
        }
        store.dispatch({ type: 'LOGIN_FULFILLED', payload: data });
        store.dispatch({ type: 'BOOT' });
    } else {
        history.replace('/login');
        store.dispatch({ type: 'LOGIN_REJECTED', payload: 'user does not have permission to access config' });
    }
})
.finally(() => {
    ReactDOM.render(
        <MuiThemeProvider theme={theme}>
            <Provider store={store}>
                <App/>
            </Provider>
        </MuiThemeProvider>,
        document.getElementById('root')
    );
});
