import { initialStateFactory, reducerFactory, sagasFactory } from 'utils/factories';
import actionsFactory from 'utils/factories/actions';
import { EQUIPMENT_ATTACHMENT } from './actionTypes';

// actions
const actions = actionsFactory(EQUIPMENT_ATTACHMENT);
export { actions };

// reducer
const initialState = initialStateFactory([]);
const reducer = reducerFactory(EQUIPMENT_ATTACHMENT);
export default (state = initialState, action) => reducer(state, action);

// sagas
const sagas = sagasFactory(EQUIPMENT_ATTACHMENT, '/equipment_attachments');
export { sagas };
