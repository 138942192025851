import { initialStateFactory, reducerFactory, sagasFactory } from 'utils/factories';
import actionsFactory from 'utils/factories/actions';
import { INSTALLATION_PICTURE } from './actionTypes';

// actions
const actions = actionsFactory(INSTALLATION_PICTURE);
export { actions };

// reducer
const initialState = initialStateFactory([]);
const reducer = reducerFactory(INSTALLATION_PICTURE);
export default (state = initialState, action) => reducer(state, action);

// sagas
const sagas = sagasFactory(INSTALLATION_PICTURE, '/installation_pictures');
export { sagas };
