import React from 'react';
import ActingMetals from './ActingMetals';
import Digital from './Digital';
import ReadingMetals from './ReadingMetals';

const Readings = props => {
    const { device } = props;

    if (device.out_protocol_name === 'Acting Metal') {
        return <ActingMetals deviceId={device.id}/>;
    } else if (device.out_protocol_name === 'Reading Metal') {
        return <ReadingMetals deviceId={device.id}/>;
    } else if (device.out_protocol_name === 'Digital') {
        return <Digital deviceId={device.id}/>;
    } else {
        throw new Error('invalid out protocol');
    }
};

export default Readings;

