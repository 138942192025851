import columns from 'utils/columns/installationZones';
import { initialStateFactory, reducerFactory, sagasFactory } from 'utils/factories';

import actionsFactory from 'utils/factories/actions';
import { INSTALLATION_ZONE } from './actionTypes';

// actions
const actions = actionsFactory(INSTALLATION_ZONE);
export { actions };

// reducer
const initialState = initialStateFactory(columns);
const reducer = reducerFactory(INSTALLATION_ZONE);
export default (state = initialState, action) => reducer(state, action);

// sagas
const sagas = sagasFactory(INSTALLATION_ZONE, '/installation_zones');
export { sagas };
