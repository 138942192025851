import Note from 'Components/Note';
import moment from 'moment-timezone';
import React from 'react';
import { unixToDate } from 'utils/date';

const LastSync = props => {
    if (props.loading) {
        return null;
    }

    return (
        <React.Fragment>
            <Note big left><b>Note:</b> This table has <b>realtime</b> values so they might be inconsistent with the rest of the app</Note>
            <Note>Last Sync @ <b>{unixToDate(moment().unix())}</b></Note>
        </React.Fragment>
    );
};

export default LastSync;
