import { initialStateFactory, reducerFactory, sagasFactory } from 'utils/factories';
import actionsFactory from 'utils/factories/actions';
import { CALIBRATION } from './actionTypes';

// actions
const actions = actionsFactory(CALIBRATION);
export { actions };

// reducer
const initialState = initialStateFactory();
const reducer = reducerFactory(CALIBRATION);
export default (state = initialState, action) => reducer(state, action);

// sagas
const sagas = sagasFactory(CALIBRATION, '/calibrations');
export { sagas };
