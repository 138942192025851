import Form from 'Components/Forms/FormGenerator';
import FormViewer from 'Components/FormViewer';
import { actions as deviceActions } from 'ducks/devices';
import React from 'react';
import { connect } from 'react-redux';
import { serializeForm } from 'utils/forms/utils';
import validators from 'utils/forms/validators';

const weeksToResolve = [
    { id: 1, name: '1 Month' },
    { id: 2, name: '2 Months' },
    { id: 3, name: '3 Months' },
    { id: 4, name: '4 Months' },
    { id: 5, name: '5 Months' },
    { id: 6, name: '6 Months' },
    { id: 7, name: '7 Months' },
    { id: 8, name: '8 Months' },
    { id: 9, name: '9 Months' },
    { id: 10, name: '10 Months' },
    { id: 11, name: '11 Months' },
    { id: 12, name: '12 Months' }
];

const Component = props => {
    const { device, submitting } = props;
    const { closeDrawer, knownProblem } = props;

    let formSchema = [
        {
            type: 'select',
            label: 'Acknowledge until: Number of weeks to resolve\x2a (max: 12 months)',
            name: 'acknowledged_until',
            formData: weeksToResolve,
            validate: [validators.required, validators.number]
        },
        {
            type: 'textArea',
            label: 'Description\x2a',
            name: 'description',
            rows: 8,
            validate: [validators.required]
        }
    ];

    const onSubmit = values => {
        const data = serializeForm(values, formSchema);

        knownProblem(device.id, data, closeDrawer);
    };

    return (
        <FormViewer label="Known Problem">
            <Form
                formSchema={formSchema}
                isSubmitting={submitting}
                onSubmit={onSubmit}
            />
        </FormViewer>
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        device: state.devices.models[ownProps.id],
        submitting: state.devices.submitting
    };
};

const mapDispatchToProps = ({
    knownProblem: deviceActions.knownProblem
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);


