import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import { isEmpty } from 'lodash';
import Accesses from 'Pages/Tree/Device/Accesses';
import Children from 'Pages/Tree/Device/Children';
import Data from 'Pages/Tree/Device/Data';
import Deploys from 'Pages/Tree/Device/Deploys';
import Indicators from 'Pages/Tree/Device/Indicators';
import InstallationPictures from 'Pages/Tree/Device/InstallationPictures';
import LogsTable from 'Pages/Tree/Device/Logs/Table';
import Readings from 'Pages/Tree/Device/Readings';
import Realtime from 'Pages/Tree/Device/Realtime';
import styles from 'Pages/Tree/Device/styles';
import React from 'react';
import { connect } from 'react-redux';
import { selectRootDevices } from 'selectors/devices';
import findWhere from 'selectors/findWhere';
import Details from './Details';
import Toolbar from './Toolbar';

const Component = props => {
    const { classes, device, childDevices, indicators, installationZones, match, measurements } = props;
    let section, Section;

    const buildingInstallationZones = findWhere(Object.keys(installationZones).map(id => installationZones[id]), { building_id: device.building_id });

    if (props.section) {
        section = props.section;
    } else if (device.new_wise_box) {
        section = 'details';
    } else if (measurements.length) {
        section = 'measurements';
    } else if (!isEmpty(childDevices)) {
        section = 'devices';
    } else {
        section = 'details';
    }

    switch (section) {
        case 'accesses':
            Section = <Accesses device={device}/>;
            break;
        case 'data':
            Section = <Data device={device}/>;
            break;
        case 'devices':
            Section = <Children childDevices={childDevices} device={device} installationZones={buildingInstallationZones}/>;
            break;
        case 'indicators':
            Section = <Indicators device={device} indicators={indicators} match={match} measurements={measurements}/>;
            break;
        case 'logs':
            Section = <LogsTable device={device}/>;
            break;
        case 'measurements':
            Section = <Readings device={device}/>;
            break;
        case 'pictures':
            Section = <InstallationPictures device={device}/>;
            break;
        case 'realtime':
            Section = <Realtime device={device}/>;
            break;
        case 'deploys':
            Section = <Deploys device={device}/>;
            break;
        default:
            Section = <Details childDevices={childDevices} device={device} installationZones={buildingInstallationZones} measurements={measurements}/>;
    }

    return (
        <Grid item xs={12} className={classes.mainContainer}>
            <Grid container className={classes.headerContainer}>
                <Toolbar currentTab={section} device={device}/>
            </Grid>
            <Grid item xs={12} className={classes.bottomContainer}>
                {Section}
            </Grid>
        </Grid>
    );
};

const mapStateToProps = (state, ownProps) => ({
    childDevices: findWhere(state.devices.ids.map(id => state.devices.models[id]), { parent_id: ownProps.deviceId }),
    device: state.devices.models[ownProps.deviceId],
    measurements: findWhere(state.measurements.ids.map(id => state.measurements.models[id]), { device_id: ownProps.deviceId }),
    indicators: state.indicators.models,
    installationZones: state.installationZones.models,
    rootDevices: selectRootDevices(state, ownProps)
});

export default connect(mapStateToProps)(withStyles(styles)(Component));
