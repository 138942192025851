import { all, put, take, takeLatest } from 'redux-saga/effects';
import {
    CLEAR_DEVICES,
    CLEAR_INDICATORS,
    CLEAR_INSTALLATION_ZONES,
    CLEAR_MEASUREMENTS,
    FETCH_BUILDING,
    FETCH_DEVICES,
    FETCH_INDICATORS,
    FETCH_INSTALLATION_ZONES,
    FETCH_MEASUREMENTS,
    FULFILLED,
    LOAD_BUILDING,
    REFRESH_BUILDING
} from './actionTypes';

// actions
export const loadBuilding = id => ({
    type: LOAD_BUILDING,
    payload: id
});

export const refreshBuilding = id => ({
    type: REFRESH_BUILDING,
    payload: id
});

// reducer
let newState;

export default (state = {}, { type, payload }) => {
    switch (type) {
        case LOAD_BUILDING:
        case REFRESH_BUILDING:
            newState = { ...state };
            delete newState[payload];
            return newState;

        case LOAD_BUILDING + FULFILLED:
            return { ...state, [payload]: true };

        default:
            return state;
    }
};

// sagas
function* loadBuildingSaga({ payload: building_id }) {
    try {
        yield put({ type: FETCH_BUILDING, payload: building_id });
        yield put({ type: FETCH_DEVICES, payload: { building_id } });
        yield put({ type: FETCH_INDICATORS, payload: { building_id, type: 'raw' } });
        yield put({ type: FETCH_INSTALLATION_ZONES, payload: { building_id } });
        yield put({ type: FETCH_MEASUREMENTS, payload: { building_id } });
        yield all([
            take(FETCH_BUILDING + FULFILLED),
            take(FETCH_DEVICES + FULFILLED),
            take(FETCH_INDICATORS + FULFILLED),
            take(FETCH_INSTALLATION_ZONES + FULFILLED),
            take(FETCH_MEASUREMENTS + FULFILLED)
        ]);
        yield put({ type: LOAD_BUILDING + FULFILLED, payload: building_id });
    } catch (error) {
        console.error(error);
    }
}

function* watchLoad() {
    yield takeLatest(LOAD_BUILDING, loadBuildingSaga);
}

function* refreshBuildingSaga({ payload: building_id }) {
    try {
        yield put({ type: CLEAR_DEVICES, payload: building_id });
        yield put({ type: CLEAR_INDICATORS, payload: building_id });
        yield put({ type: CLEAR_INSTALLATION_ZONES, payload: building_id });
        yield put({ type: CLEAR_MEASUREMENTS, payload: building_id });
        yield put({ type: LOAD_BUILDING, payload: building_id });
    } catch (error) {
        console.error(error);
    }
}

function* watchRefresh() {
    yield takeLatest(REFRESH_BUILDING, refreshBuildingSaga);
}

const sagas = [
    watchLoad,
    watchRefresh
];

export { sagas };
