import Table from 'Components/SimpleTable';
import React from 'react';
import { connect } from 'react-redux';
import attachmentsSelector from 'selectors/equipmentAttachments';
import getReadableFileSize from 'utils/getReadableFileSize';

const columns = [
    { label: 'Name', dataKey: 'name' },
    { label: 'Type', dataKey: 'mime_type' },
    { label: 'Size', dataKey: 'size', transform: attachment => getReadableFileSize(attachment.size) }
];

const Attachments = props => {
    const { attachments } = props;
    const { openAttachment } = props;

    return (
        <Table
            columns={columns}
            onRowClick={openAttachment}
            rows={attachments}
        />
    );
};

const mapStateToProps = (state, ownProps) => {
    const equipmentId = ownProps.equipmentId || state.drawerView.entityId;

    return {
        attachments: attachmentsSelector(state, equipmentId)
    };
};

export default connect(mapStateToProps)(Attachments);
