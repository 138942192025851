import TabsToolbar from 'Components/TabsToolbar';
import React from 'react';

const Toolbar = ({ currentTab, device }) => {
    const deviceTabs = [
        { text: 'Details', id: 'details', url: `/devices/${device.id}/details` },
        { text: 'Devices', id: 'devices', url: `/devices/${device.id}/devices` },
        { text: 'Data', id: 'data', url: `/devices/${device.id}/data` },
        { text: 'Logs', id: 'logs', url: `/devices/${device.id}/logs` },
        { text: 'Pictures', id: 'pictures', url: `/devices/${device.id}/pictures` }
    ];

    if (device.new_wise_box) {
        if (device.last_deployed_at) {
            deviceTabs.splice(3, 0, { text: 'Realtime', id: 'realtime', url: `/devices/${device.id}/realtime` });
        }
        deviceTabs.push({ text: 'Deploys', id: 'deploys', url: `/devices/${device.id}/deploys` });
    }

    return (
        <TabsToolbar currentTab={currentTab} tabs={deviceTabs}/>
    );
};

export default Toolbar;
