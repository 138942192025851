import Form from 'Components/Forms/FormGenerator';
import FormViewer from 'Components/FormViewer';
import { actions } from 'ducks/buildings';
import React from 'react';
import { connect } from 'react-redux';
import { serializeForm } from 'utils/forms/utils';
import validators from 'utils/forms/validators';

const UploadDevices = props => {
    const { buildingId, uploading } = props;
    const { uploadDevices } = props;

    const formSchema = [
        {
            accept: 'text/csv\n',
            label: 'File\x2a',
            name: 'file',
            type: 'file',
            validate: [validators.required]
        }
    ];

    const onSubmit = values => {
        const data = serializeForm(values, formSchema);
        uploadDevices(buildingId, data.file);
    };

    return (
        <FormViewer label="Upload Devices File">
            <a href="/static/example.csv" target="_blank">Download example file</a>
            <br/>
            <br/>
            <Form
                buttonLabel="Upload"
                formSchema={formSchema}
                isSubmitting={uploading}
                onSubmit={onSubmit}
            />
        </FormViewer>
    );
};

const mapStateToProps = state => ({
    uploading: state.buildings.uploadingDevices
});

const mapDispatchToProps = ({
    uploadDevices: actions.uploadDevices
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadDevices);
