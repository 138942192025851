import columns from 'utils/columns/templates';
import { initialStateFactory, reducerFactory, sagasFactory } from 'utils/factories';

import actionsFactory from 'utils/factories/actions';
import { TEMPLATE } from './actionTypes';

// actions
const actions = actionsFactory(TEMPLATE);
export { actions };

// reducer
const initialState = initialStateFactory(columns);
const reducer = reducerFactory(TEMPLATE);
export default (state = initialState, action) => reducer(state, action);

// sagas
const sagas = sagasFactory(TEMPLATE, '/installation_templates');
export { sagas };
