import React from 'react';

const Note = ({ big = false, children, left = false }) => {
    return (
        <div style={{ fontSize: big ? 12 : 10, textAlign: left ? 'left' : 'right' }}>
            {children}
        </div>
    );
};

export default Note;
