import { createSelector } from 'reselect';

const equipmentAttachments = state => state.equipmentAttachments.models;
const equipmentId = (_, equipmentId) => equipmentId;

const equipmentAttachmentsArray = createSelector(
    equipmentAttachments,
    equipmentId,
    (attachments, equipmentId) => Object.values(attachments).filter(reading => reading.equipment_id === equipmentId)
);

export default equipmentAttachmentsArray;
