import EntityManageView from 'Components/EntityManageView';
import { actions } from 'ducks/equipmentAttachments';
import React from 'react';
import { connect } from 'react-redux';
import validators from 'utils/forms/validators';

const Component = props => {
    const { equipmentId } = props;

    const formSchema = [
        {
            label: 'Name\x2a',
            name: 'name',
            type: 'text',
            validate: [validators.required]
        },
        {
            label: 'Comment',
            name: 'comment',
            type: 'textArea'
        },
        {
            accept: 'application/pdf,application/vnd.ms-excel,application/zip,image/jpg,image/png,text/plain',
            label: 'File\x2a',
            name: 'file',
            type: 'file',
            validate: [validators.required]
        }
    ];

    return (
        <EntityManageView
            {...props}
            extraData={{ equipment_id: equipmentId }}
            formSchema={formSchema}
            label="New Attachment"
        />
    );
};

const mapStateToProps = state => ({
    equipmentId: state.drawerView.entityId,
    submitting: state.equipmentAttachments.submitting
});

const mapDispatchToProps = ({
    create: actions.create,
    update: actions.update
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
