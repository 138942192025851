// GLOBAL AFFIXES
export const FULFILLED = '_FULFILLED';
export const REJECTED = '_REJECTED';

// GLOBAL Entities
export const CALIBRATION = 'CALIBRATION';
export const DEPLOY = 'DEPLOY';
export const DEVICE = 'DEVICE';
export const EQUIPMENT = 'EQUIPMENT';
export const EQUIPMENT_ATTACHMENT = 'EQUIPMENT_ATTACHMENT';
export const EQUIPMENT_CATEGORY = 'EQUIPMENT_CATEGORY';
export const INDICATOR = 'INDICATOR';
export const INSTALLATION_PICTURE = 'INSTALLATION_PICTURE';
export const INSTALLATION_ZONE = 'INSTALLATION_ZONE';
export const METADATA = 'METADATA';
export const METADATA_RULE = 'METADATA_RULE';
export const MEASUREMENT = 'MEASUREMENT';
export const PROTOCOL = 'PROTOCOL';
export const READING = 'READING';
export const TEMPLATE = 'TEMPLATE';
export const USER = 'USER';
export const WISE_BOX = 'WISE_BOX';

// bootstrap
export const BOOT = 'BOOT';
export const BOOTSTRAP = 'BOOTSTRAP';

// login failed
export const CLEAN_ALERT = 'CLEAN_ALERT';

// authentication
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';

// ui
export const TOGGLE_MENU = 'TOGGLE_MENU';
export const TOGGLE_MODAL = 'TOGGLE_MODAL';
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';

// equipments
export const FETCH_EQUIPMENTS = 'FETCH_EQUIPMENTS';

// form initializers
export const INITIALIZE_FORM = 'INITIALIZE_FORM';
export const INITIALIZE_FORM_CLEAN_UP = 'INITIALIZE_FORM_CLEAN_UP';

// buildings
export const BUILDING = 'BUILDING';
export const LOAD_BUILDING = 'LOAD_BUILDING';
export const REFRESH_BUILDING = 'REFRESH_BUILDING';
export const REFRESH_BUILDING_STATUS = 'REFRESH_BUILDING_STATUS';
export const FETCH_BUILDING = 'FETCH_BUILDING';
export const FETCH_BUILDINGS = 'FETCH_BUILDINGS';
export const UPLOAD_DEVICES = 'UPLOAD_DEVICES';

// device tree
export const COLLAPSE_TREE = 'COLLAPSE_TREE';
export const EXPAND_TREE = 'EXPAND_TREE';
export const TOGGLE_BUILDINGS = 'TOGGLE_BUILDINGS';
export const TOGGLE_NODE = 'TOGGLE_NODE';
export const TOGGLE_PROBLEMS = 'TOGGLE_PROBLEMS';

// devices
export const CLEAR_DEVICES = 'CLEAR_DEVICES';
export const DELETE_DEVICE = 'DELETE_DEVICE';
export const DELETE_FUTURE = 'DELETE_FUTURE';
export const FETCH_DEVICES = 'FETCH_DEVICES';
export const REGENERATE_SEED_FILES = 'REGENERATE_SEED_FILES';

// equipment categories
export const FETCH_EQUIPMENT_ATTACHMENTS = 'FETCH_EQUIPMENT_ATTACHMENTS';
export const FETCH_EQUIPMENT_CATEGORIES = 'FETCH_EQUIPMENT_CATEGORIES';

// indicators
export const FETCH_INDICATOR = 'FETCH_INDICATOR';
export const FETCH_INDICATORS = 'FETCH_INDICATORS';
export const CLEAR_INDICATORS = 'CLEAR_INDICATORS';

// installation Zones
export const FETCH_INSTALLATION_ZONES = 'FETCH_INSTALLATION_ZONES';
export const CLEAR_INSTALLATION_ZONES = 'CLEAR_INSTALLATION_ZONES';

// measurements
export const FETCH_MEASUREMENT = 'FETCH_MEASUREMENT';
export const FETCH_MEASUREMENTS = 'FETCH_MEASUREMENTS';
export const CLEAR_MEASUREMENTS = 'CLEAR_MEASUREMENTS';
export const UNLINK_MEASUREMENT = 'UNLINK_MEASUREMENT';

// metadata
export const FETCH_METADATAS = 'FETCH_METADATAS';

// metadataRules
export const FETCH_METADATA_RULES = 'FETCH_METADATA_RULES';

// protocols
export const FETCH_PROTOCOLS = 'FETCH_PROTOCOLS';

// readings
export const FETCH_READINGS = 'FETCH_READINGS';

// templates
export const FETCH_TEMPLATES = 'FETCH_TEMPLATES';

// users
export const FETCH_USERS = 'FETCH_USERS';

// wise_boxes
export const CLAIM_WISE_BOX = 'CLAIM_WISE_BOX';
export const FETCH_WISE_BOXES = 'FETCH_WISE_BOXES';
export const START_POOLING_WISE_BOXES = 'START_POOLING_WISE_BOXES';
export const STOP_POOLING_WISE_BOXES = 'STOP_POOLING_WISE_BOXES';
